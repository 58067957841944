import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { gallery } from '../constants'

const Gallery = () => {
  const navigate = useNavigate()
  const [current, setCurrent] = useState(0)
  return (
    <div className='bg-dimBlack md:p-[80px] px-4 py-10 text-white'>
      <div className='flex flex-col md:items-center items-start'>
        <h1 className="font-bold md:text-center mb-[24px]">
          OUR GALLERY
        </h1>
        <p className="font-medium md:text-center md:w-[60%] w-[80%]">
          Check out Images of our Ministry and Event Space.
        </p>
      </div>
      <div className="flex items-center justify-between lg:mt-16 mt-10">
        <div className={`text-black ${current > 0 ? 'bg-white' : 'bg-[#FFFFFF85]'} w-10 h-10 hidden lg:flex items-center justify-center text-[24px] font-bold ${current > 0 ? 'cursor-pointer' : 'cursor-default'}`} onClick={() => { current > 0 && setCurrent(prev => prev - 1) }}>
          &lt;
        </div>
        <div className="w-full flex justify-center">
          {
            <div className="flex justify-center w-[80%] h-[500px]">
              <img src={gallery[current].galleryImage} alt="" className="object-cover" />
            </div>
          }
        </div>
        <div className={`text-black ${current < gallery.length - 1 ? 'bg-white' : 'bg-[#FFFFFF85]'} w-10 h-10 hidden lg:flex items-center justify-center text-[24px] font-bold ${current < gallery.length - 1 ? 'cursor-pointer' : 'cursor-default'}`} onClick={() => { current < gallery.length - 1 && setCurrent(prev => prev + 1) }}>
          &gt;
        </div>
      </div>
      <div className="flex lg:hidden justify-center items-center gap-8 mt-8">
        <div className={`text-black ${current > 0 ? 'bg-white' : 'bg-[#FFFFFF85]'} w-10 h-10 flex items-center justify-center text-[24px] font-bold ${current > 0 ? 'cursor-pointer' : 'cursor-default'}`} onClick={() => { current > 0 && setCurrent(prev => prev - 1) }}>
          &lt;
        </div>
        <div className={`text-black ${current < gallery.length - 1 ? 'bg-white' : 'bg-[#FFFFFF85]'} w-10 h-10 flex items-center justify-center text-[24px] font-bold ${current < gallery.length - 1 ? 'cursor-pointer' : 'cursor-default'}`} onClick={() => { current < gallery.length - 1 && setCurrent(prev => prev + 1) }}>
          &gt;
        </div>
      </div>
      <div className="flex justify-center w-full mt-10">
        <button className="btn4 px-3 py-2 md:px-4 md:py-2 text-[12px] md:text-[18px] hover:bg-black hover:text-white" onClick={() => navigate('/gallery')}>VIEW ALL</button>
      </div>
    </div>
  )
}

export default Gallery