import './App.css';
import { Routes, Route } from 'react-router-dom'
import { Navbar, Footer } from './components';
import { HomePage, AboutUsPage, EventsPage, ContactUs, OurPraises, OurGallery, ErrorPage } from './pages';

function App() {
  return (
    <div className="font-montserrat">
      <Navbar />
      <div className='pt-[80px]'>
        <Routes>
          <Route path='/' element={ <HomePage /> } />
          <Route path='/about' element={ <AboutUsPage /> } />
          <Route path='/events' element={ <EventsPage /> } /> 
          <Route path='/contact' element={ <ContactUs /> } />
          <Route path='/praises' element={ <OurPraises /> } />
          <Route path='/gallery' element={ <OurGallery /> } />
          <Route path='*' element={ <ErrorPage /> } />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
