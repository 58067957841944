import React from 'react'
import { Contact, Testimonials, WhiteGallery } from '../components'

const OurGallery = () => {
  return (
    <div>
      <WhiteGallery />
      <Testimonials />
      <Contact />
    </div>
  )
}

export default OurGallery