import React from 'react'
import { faqs } from '../constants'
import { Question } from '../components'
const Faq = () => {
  
  return (
    <div className='md:p-[80px] px-4 py-10'>
      <div className='flex flex-col items-start'>
        <h1 className="font-bold mb-[16px] md:w-[40%] w-full">
          FREQUENTLY ASKED QUESTIONS
        </h1>
        <p className="font-medium md:w-[40%] w-[80%]">
          A couple of answers to some of the most frequently asked questions.
        </p>
      </div>
      <div className="flex flex-col mt-[40px] gap-[30px]">
        {
          faqs.map(faq => (
            <Question items={faq} />
          ))
        }
      </div>
    </div>
  )
}

export default Faq