import React from 'react'

const Events = () => {
  return (
    <div className='md:p-[80px] md:pb-[40px] px-4 py-10'>
      <div className='flex flex-col md:items-center'>
        <h1 className="font-bold md:text-center mb-[8px]">
          OUR EVENT SPACE
        </h1>
        <h2 className="font-semibold md:text-center md:w-[60%]">
          Available for usage, just book below
        </h2>
      </div>
      <div className="flex flex-col items-center mt-[40px] gap-[24px]">
        <div className="flex items-center p-2 md:p-[24px] justify-between border rounded-[8px] md:w-[70%] w-full">
          <div className="flex items-center gap-[16px]">
            <div className="w-[84px] h-[84px] flex items-center justify-center bg-black rounded-[5px]">
              <h5 className="text-white text-center leading-6">
                Everyday Program
              </h5>
            </div>
            <div>
              <p className="font-medium md:text-[18px] text-[12px]">
                9:00am - 1:00pm
              </p>
              <h2 className="font-semibold mb-[8px] md:text-[24px] text-[14px]">
                Praise & Prayer Session
              </h2>
            </div>
          </div>
          <button className="btn3 px-2 py-1 md:px-3 md:py-1 text-[10px] md:text-[14px]">
            BOOK NOW
          </button>
        </div>
        <div className="flex items-center p-2 md:p-[24px] justify-between border rounded-[8px] md:w-[70%] w-full">
          <div className="flex items-center gap-[16px]">
            <div className="w-[84px] h-[84px] flex items-center justify-center bg-black rounded-[5px]">
              <h5 className="text-white text-center leading-6">
                Everyday Program
              </h5>
            </div>
            <div>
              <p className="font-medium md:text-[18px] text-[12px]">
                2:00pm - 6:00pm
              </p>
              <h2 className="font-semibold mb-[8px] md:text-[24px] text-[14px]">
                Praise & Prayer Session
              </h2>
            </div>
          </div>
          <button className="btn3 px-2 py-1 md:px-3 md:py-1 text-[10px] md:text-[14px]">
            BOOK NOW
          </button>
        </div>
        <div className="flex items-center p-2 md:p-[24px] justify-between border rounded-[8px] md:w-[70%] w-full">
          <div className="flex items-center gap-[16px]">
            <div className="w-[84px] h-[84px] flex items-center justify-center bg-black rounded-[5px]">
              <h5 className="text-white text-center leading-6">
                Everyday Program
              </h5>
            </div>
            <div>
              <p className="font-medium md:text-[18px] text-[12px]">
                7:00pm - 11:00pm
              </p>
              <h2 className="font-semibold mb-[8px] md:text-[24px] text-[14px]">
                Praise & Prayer Session
              </h2>
            </div>
          </div>
          <button className="btn3 px-2 py-1 md:px-3 md:py-1 text-[10px] md:text-[14px]">
            BOOK NOW
          </button>
        </div>
      </div>
    </div>
  )
}

export default Events