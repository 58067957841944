import React from 'react'
import { blackFacebook, blackInstagram, blackYoutube, dpmWhiteLogo } from '../assets'

const Footer = () => {
  return (
    <div className='mt-[40px]'>
      <div className="px-4 md:px-[80px]">
        <div className='border-t border-black py-10 md:py-[80px] flex md:flex-row flex-col justify-between items-center md:items-start gap-10 text-center md:text-start'>
          <img src={dpmWhiteLogo} alt="" />
          <div className="flex flex-col">
            <p className="font-bold mb-[16px]">
              ALL PAGES
            </p>
            <div className="flex flex-col gap-[8px]">
              <p className="font-semibold">
                Home
              </p>
              <p className="font-semibold">
                About Us
              </p>
              <p className="font-semibold">
                Events
              </p>
              <p className="font-semibold">
                Contact Us
              </p>
              <p className="font-semibold">
                Our Sermons
              </p>
            </div>
          </div>
          <div className="flex flex-col">
            <p className="font-bold mb-[16px]">
              EXTERNAL LINKS
            </p>
            <div className="flex flex-col gap-[8px]">
              <p className="font-semibold">
                Call Us
              </p>
              <p className="font-semibold">
                Our Location
              </p>
            </div>
          </div>
          <div className="flex flex-col">
            <p className="font-bold mb-[16px]">
              OUR SOCIALS
            </p>
            <div className="flex justify-center md:justify-start gap-[16px]">
              <a href="https://www.instagram.com/dailypraiseministry/" target='_blank' rel="noreferrer" className="">
                <img src={blackInstagram} alt="" />
              </a>
              <a href="https://www.facebook.com/profile.php?id=100086655818737&mibextid=ZbWKwL" target='_blank' rel="noreferrer" className="">
                <img src={blackFacebook} alt="" />
              </a>
              <a href="https://www.youtube.com/@DAILYPRAISEMINISTRY" target='_blank' rel="noreferrer" className="">
                <img src={blackYoutube} alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center md:justify-end md:px-[80px] bg-black text-white py-[16px]">
         <span className="text-[14px] font-semibold">
          2023 Daily Praise Ministry
        </span> 
      </div>
    </div>
  )
}

export default Footer