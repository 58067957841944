import React from 'react'
import { Sermons, Contact, Testimonials } from '../components'

const OurSermons = () => {
  return (
    <div>
      <Sermons />
      <Testimonials />
      <Contact />
    </div>
  )
}

export default OurSermons