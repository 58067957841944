import { galleryImage, galleryImage1, galleryImage2, galleryImage3, galleryImage4, galleryImage5, galleryImage6, galleryImage7, galleryImage8, galleryImage9, testimonialsImage } from "../assets";

export const navLinks = [
  {
    id: 1,
    path: '/',
    linkName: 'Home',
  },
  {
    id: 2,
    path: '/about',
    linkName: 'About Us',
  },
  {
    id: 3,
    path: '/events',
    linkName: 'Events',
  },
  {
    id: 4,
    path: '/contact',
    linkName: 'Contact Us',
  },
  {
    id: 5,
    path: '/praises',
    linkName: 'Our Praises',
  },
];

export const faqs = [
  {
    id: 1,
    title: 'How can i join Daily praise worship session?',
    summary: 'You can come join us at our Location or just stream the session via our YouTube Channel.'
  },
  {
    id: 2,
    title: 'How can i join Daily praise worship session?',
    summary: 'You can come join us at our Location or just stream the session via our YouTube Channel.'
  },
  {
    id: 3,
    title: 'How can i join Daily praise worship session?',
    summary: 'You can come join us at our Location or just stream the session via our YouTube Channel.'
  },
]

export const testimonials = [
  {
    id: 1,
    testimonialImage: testimonialsImage,
    testimonialName: 'Mrs. Adebunmi Sarah',
    summary: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo.'
  },
  {
    id: 2,
    testimonialImage: testimonialsImage,
    testimonialName: 'Mrs. Dosunmu Cynthia',
    summary: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo.'
  },
  {
    id: 3,
    testimonialImage: testimonialsImage,
    testimonialName: 'Mrs. Abimbola Adebayo',
    summary: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo.'
  },
  {
    id: 4,
    testimonialImage: testimonialsImage,
    testimonialName: 'Mrs. Dosunmu Rachael',
    summary: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo.'
  },
]

export const sermons = [
  {
    id: 1,
    title: 'DailyPraiseOnline',
    sermonName: 'Programs on YouTube'
  },
  {
    id: 2,
    title: 'DailyPraiseOnline',
    sermonName: 'Events on YouTube'
  },
  {
    id: 3,
    title: 'DailyPraiseOnline',
    sermonName: 'Programs on YouTube'
  },
  {
    id: 4,
    title: 'DailyPraiseOnline',
    sermonName: 'Events on YouTube'
  },
  {
    id: 5,
    title: 'DailyPraiseOnline',
    sermonName: 'Programs on YouTube'
  },
  {
    id: 6,
    title: 'DailyPraiseOnline',
    sermonName: 'Events on YouTube'
  },
  {
    id: 7,
    title: 'DailyPraiseOnline',
    sermonName: 'Programs on YouTube'
  },
  {
    id: 8,
    title: 'DailyPraiseOnline',
    sermonName: 'Events on YouTube'
  },
]


export const gallery = [
  {
    id: 1,
    galleryImage: galleryImage1,
  },
  {
    id: 2,
    galleryImage: galleryImage2,
  },
  {
    id: 3,
    galleryImage: galleryImage3,
  },
  {
    id: 4,
    galleryImage: galleryImage4,
  },
  {
    id: 5,
    galleryImage: galleryImage5,
  },
  {
    id: 6,
    galleryImage: galleryImage6,
  },
  {
    id: 7,
    galleryImage: galleryImage7,
  },
  {
    id: 8,
    galleryImage: galleryImage8,
  },
  {
    id: 9,
    galleryImage: galleryImage9,
  },
]

