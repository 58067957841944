import React from 'react'
import { useNavigate } from 'react-router-dom'

const Upcoming = () => {
  const navigate = useNavigate()
  return (
    <div className='md:px-[80px] md:py-[80px] px-4 py-10'>
      <div className="flex justify-between items-center mb-[40px]">
        <h2 className="font-semibold">
          Upcoming Events
        </h2>
        <p 
          className="font-medium text-primary cursor-pointer text-[12px] md:text-[18px]" 
          onClick={() => navigate('/events')}
        >
          View All Events
        </p>
      </div>
      <div className="w-full md:divide-x divide-y divide-solid flex md:flex-row flex-col md:justify-between md:items-center border rounded-[5px]">
        <div className='md:w-[50%] w-full md:p-[24px] p-2 flex justify-between items-center'>
          <div className="w-full flex justify-between items-center">
            <div className="flex items-center gap-4">
              <div className="w-[84px] h-[84px] flex items-center justify-center bg-black rounded-[5px]">
                <h5 className="text-white text-center leading-6">
                  Everyday Program
                </h5>
              </div>
              <div>
                <h2 className="font-semibold mb-[8px] md:text-[24px] text-[14px]">
                  Praise & Prayer Session
                </h2>
                <p className="font-medium md:text-[18px] text-[12px]">
                  9:00am - 1:00pm
                </p>
              </div>
            </div>
            <button className="btn3 px-2 py-1 md:px-3 md:py-1 text-[10px] md:text-[14px]">
              BOOK NOW
            </button>
          </div>
        </div>
        <div className='md:w-[50%] w-full md:p-[24px] p-2 flex justify-between items-center'>
          <div className="w-full flex justify-between items-center">
            <div className="flex items-center gap-4">
              <div className="w-[84px] h-[84px] flex items-center justify-center bg-black rounded-[5px]">
                <h5 className="text-white text-center leading-6">
                  Everyday Program
                </h5>
              </div>
              <div>
                <h2 className="font-semibold mb-[8px] md:text-[24px] text-[14px]">
                  Praise & Prayer Session
                </h2>
                <p className="font-medium md:text-[18px] text-[12px]">
                  2:00pm - 6:00pm
                </p>
              </div>
            </div>
            <button className="btn3 px-2 py-1 md:px-3 md:py-1 text-[10px] md:text-[14px]">
              BOOK NOW
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Upcoming