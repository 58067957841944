import React from 'react'
import { AboutUs, Contact, Testimonials } from '../components'

const AboutUsPage = () => {
  return (
    <div>
      <AboutUs />
      <Testimonials />
      <Contact />
    </div>
  )
}

export default AboutUsPage