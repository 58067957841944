import React from 'react'

const ErrorPage = () => {
  return (
    <div className='flex justify-center items-center h-[80vh]'>
      <h1 className="font-bold">
        Error! Page does not exist.
      </h1>
    </div>
  )
}

export default ErrorPage