import React from 'react'
import { Contact, Testimonials } from '../components'

const ContactUs = () => {
  return (
    <div>
      <Contact />
      <Testimonials />
    </div>
  )
}

export default ContactUs