import React, { useState } from 'react'
import { dropdown, dropup, helpIcon } from '../assets'

const Question = (props) => {
  const [isShown, setIsShown] = useState(false)
  const toggleShowFalse = () => {
    setIsShown(false)
  }
  const toggleShowTrue = () => {
    setIsShown(true)
  }
  return (
    <div className="md:p-6 p-4 rounded-[10px] border-black border-2 flex justify-between items-start">
      <div className="flex items-start md:gap-[40px] gap-4">
        <img src={helpIcon} alt="" className='w-6' />
        <div>
          <h2 className="font-bold text-[16px] md:text-[24px]">
            {props.items.title}
          </h2>
          {
            isShown && (
              <p className="font-semibold md:w-[72%] w-full text-[12px] md:text-[18px] md:mt-4 mt-2">
                {props.items.summary}
              </p>
            )
          }
        </div>
      </div>
      {
        isShown ? (
          <img src={dropup} alt="" onClick={toggleShowFalse} className='cursor-pointer w-6' />
        ) : (
          <img src={dropdown} alt="" onClick={toggleShowTrue} className='cursor-pointer w-6' />
        )
      }
    </div>
  )
}

export default Question