import React from 'react'
import { AboutUs, Contact, Faq, Gallery, HeroSection, Sermons, Testimonials, Upcoming } from '../components'

const HomePage = () => {
  return (
    <div>
      <HeroSection />
      <Upcoming />
      <AboutUs />
      <Sermons />
      <Testimonials />
      <Gallery />
      <Faq />
      <Contact />
    </div>
  )
}

export default HomePage