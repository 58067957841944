import React from 'react'
import { useNavigate } from 'react-router-dom'
import { heroBg, whiteFacebook, whiteInstagram, whiteYoutube } from '../assets'

const HeroSection = () => {
  const navigate = useNavigate()
  const styles = {
    backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(84, 84, 84, 0.02)), url(${heroBg})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
  }

  return (
    <div style={styles} className='md:px-[80px] px-4 md:py-[70px] py-8 text-white'>
      <div className='md:w-[45%] w-[100%]'>
        <h1 className="font-bold">
          DAILY PRAISE MINISTRY.
        </h1>
        <h2 className="font-bold my-[16px]">
          God meets you where you are.
        </h2>
        <p className="font-medium mb-[40px]">
          Praise affects you, it affects the devil, and it affects God. It touches everything and every part of your life. Likewise, a lack of praise affects you in a negative way, turns the devil loose in your life, and doesn’t bless God. You have to get this area of your life right.
        </p>
        <div className="flex items-center gap-[24px]">
          <button className="btn2 px-3 py-2 md:px-4 md:py-2 text-[12px] md:text-[18px]" onClick={() => navigate('/contact')} >LET'S TALK</button>
          <button className="btn5 px-3 py-2 md:px-4 md:py-2 text-[12px] md:text-[18px]" onClick={() => navigate('/about')}>ABOUT US</button>
        </div>
        <div className="flex items-center gap-[24px] mt-[40px]">
          <a href="https://www.instagram.com/dailypraiseministry/" target='_blank' rel="noreferrer" className="">
            <img src={whiteInstagram} alt="" className='cursor-pointer' />
          </a>
          <a href="https://www.facebook.com/profile.php?id=100086655818737&mibextid=ZbWKwL" target='_blank' rel="noreferrer" className="">
            <img src={whiteFacebook} alt="" className='cursor-pointer' />
          </a>
          <a href="https://www.youtube.com/@DAILYPRAISEMINISTRY" target='_blank' rel="noreferrer" className="">
            <img src={whiteYoutube} alt="" className='cursor-pointer' />
          </a>
          
        </div>
      </div>
    </div>
  )
}

export default HeroSection