import React, { useState } from 'react'
import { leftArrow, rightArrow } from '../assets'
import { testimonials } from '../constants'

const Testimonials = () => {
  const [current, setCurrent] = useState(0)

  // function nextImage() {
  //   setCurrent(prev => prev + 1)
  // }

  // function previousImage() {
  //   setCurrent(prev => prev - 1)
  //   console.log(current)
  // }
  return (
    <div className='md:p-[80px] px-4 py-10'>
      <div className='flex flex-col md:items-center items-start'>
        <h1 className="font-bold md:text-center mb-[16px]">
          TESTIMONIALS
        </h1>
        <h2 className="font-semibold md:text-center md:w-[60%] w-[80%]">
          We'll let our members do the talking
        </h2>
      </div>
      <div className="flex md:flex-row flex-col md:items-center border rounded-[8px] p-[24px] md:gap-[60px] gap-6 border-black my-[40px]">
        <img src={testimonials[current].testimonialImage} alt="" />
        <div>
          <p className="font-normal">
            {testimonials[current].summary}
          </p>
          <h3 className="font-bold my-[24px]">
            {testimonials[current].testimonialName}
          </h3>
          {/* <img src={testimonial1} alt="" /> */}
          <div className="flex gap-[4px] items-center">
            {
              testimonials.map((testimonial, idx) => {
                if(idx === current) {
                  return (
                    <div className="selected"></div>
                  )
                }
                return (
                  <div className="unselected"></div>
                )
              })
            }
          </div>
        </div>
      </div>
      <div className="flex justify-center gap-[24px]">
        {
          current > 0 && (
            <img src={leftArrow} alt="" onClick={() => setCurrent(prev => prev - 1)} className='cursor-pointer w-10 h-10' />
          )
        }
        {
          current < testimonials.length - 1 && (
            <img src={rightArrow} alt="" onClick={() => setCurrent(prev => prev + 1)} className='cursor-pointer w-10 h-10' />
          )
        }
      </div>
    </div>
  )
}

export default Testimonials