import React, { useState } from 'react'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { dpmLogo, menu, close } from '../assets'
import { navLinks } from '../constants'

const Navbar = () => {
  const navigate = useNavigate()
  const [toggle, setToggle] = useState(false)

  return (
    <div className='px-[16px] sm:px-[80px] py-[16px] flex justify-between bg-black items-center text-white font-medium fixed left-0 right-0 z-50'>
      <Link to='/'>
        <img src={dpmLogo} alt="" className='sm:h-[60px] h-[50px]' />
      </Link>
      <div className="sm:flex justify-end items-center gap-[40px] hidden">
        {
          navLinks.map(navLink => (
            <NavLink 
              key={navLink.id} 
              to={navLink.path} 
              className= ''
              style={({ isActive }) => { return {
              textUnderlineOffset: isActive ? '32px' : '',
              fontWeight: isActive ? 'bold' : '',
              textDecoration: isActive ? 'underline' : '',
              textDecorationThickness: isActive ? '5px' : '',
             }; }}
            >
              {navLink.linkName}
            </NavLink>
          ))
        }
        <button onClick={() => navigate('/contact')} className="btn1 hover:bg-gray-400 hover:text-white">LET'S TALK</button>
      </div>
      <div className="lg:hidden flex items-center">
        <img src={toggle ? close : menu} alt="" className='cursor-pointer w-6' onClick={() => setToggle(prev => !prev)} />
        <div
          className={`${toggle ? 'flex' : 'hidden'} justify-center items-center pt-6 px-6 bg-[rgba(0,0,0,0.9)] text-white absolute top-20 right-0 left-0 h-[90vh]`}
        >
          <ul
            className="list-none items-center flex flex-col gap-10 pb-[24px]"
          >
            {
              navLinks.map(navLink => (
                <Link to={navLink.path} className='text-white hover:text-gray-500 text-[16px]' onClick={() => setToggle(prev => !prev)}>
                  {navLink.linkName}
                </Link>
              ))
            }
            <button 
              onClick={() => {
                navigate('/contact')
                setToggle(prev => !prev)
              }} className="btn1 px-3 py-2 md:px-4 md:py-2 text-[12px] md:text-[18px]">LET'S TALK</button>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Navbar