import React from 'react'
import { useNavigate } from 'react-router-dom'
import { aboutImage } from '../assets'

const AboutUs = () => {
  const navigate = useNavigate()
  return (
    <div 
      className='flex md:flex-row flex-col justify-between items-center md:p-[80px] md:gap-[60px] gap-6 px-4 py-10'
    >
      <div>
        <h1 className="font-bold">
          ABOUT US
        </h1>
        <p className="font-medium mt-[24px] mb-[40px]">
          Living a life of praise is not only the most enjoyable way to live, but it’s also one of the most powerful ways to change your life. Praise isn’t like the caboose that just follows what happens, but it’s more like the engine of a train that makes things happen.
        </p>
        <h2 className="font-bold">
          OUR MISSION:
        </h2>
        <p className="font-medium mt-[24px] mb-[40px]">
          To Praise God in his sanctuary; praise him in his mighty heavens. Praise him for his acts of power; praise him for his surpassing greatness. Let everything that has breath praise the LORD. (Psalm 150)
        </p>
        <button className="btn2 px-3 py-2 md:px-4 md:py-2 text-[12px] md:text-[18px] hover:bg-gray-400 hover:text-black" onClick={() => navigate('/about')}>
          KNOW MORE
        </button>
      </div>
      <img src={aboutImage} alt="" />
    </div>
  )
}

export default AboutUs