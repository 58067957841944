import React from 'react'
import { gallery } from '../constants'

const WhiteGallery = () => {
  return (
    <div className='bg-white md:p-[80px] px-4 py-10 text-black'>
      <div className='flex flex-col md:items-center items-start'>
        <h1 className="font-bold md:text-center mb-[24px]">
          OUR FULL GALLERY
        </h1>
        <p className="font-medium md:text-center md:w-[60%] w-[80%]">
          Check out Images of our Ministry and Event Space.
        </p>
      </div>
      <div className="grid md:grid-cols-3 grid-cols-1 gap-6 mt-10">
        {
          gallery.map(gal => (
            <img src={gal.galleryImage} alt="" />
          ))
        }
      </div>
    </div>
  )
}

export default WhiteGallery