import React, { useRef } from 'react'
import { useForm } from 'react-hook-form';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { contactImage } from '../assets'

const SERVICE_ID = process.env.REACT_APP_SERVICE_ID;
const TEMPLATE_ID = process.env.REACT_APP_TEMPLATE_ID_CONTACT;
const USER_ID = process.env.REACT_APP_USERID;


const Contact = () => {
  const form = useRef()
  const { register, handleSubmit, reset } = useForm();

  const onSubmit = (e) => {
    
    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, form.current, USER_ID)
      .then((result) => {
        console.log(result);
        toast.success("Message Sent Successfully");
      }, (error) => {
        console.log(error);
        toast.error("Something went wrong. Try again");
      });
      // .then(
      //   (result) => {
      //     // console.log(result)
      //     alert('Form Sent')
      //   },
      //   (err) => {
      //     // console.log(err.text);
      //   }
      // );
    reset();
  };

  const handleError = (errors) => console.log(errors);

  return (
    <div className='bg-dimBlack text-white flex md:flex-row flex-col md:p-[80px] px-4 py-10 items-start md:gap-[80px] gap-10 w-full'>
      <div className='w-[55%]'>
        <div className='flex flex-col items-start'>
          <h1 className="font-bold mb-[16px] md:w-[50%] w-full">
            Contact Us
          </h1>
          <p className="font-medium md:w-[75%] w-full">
            We are available whenever you reach out to us, just send Us a message and we'll get back to you Soonest.
          </p>
        </div>
        <form ref={form} className='flex flex-col gap-[24px] mt-[40px]' onSubmit={handleSubmit(onSubmit, handleError)}>
          <div className='flex flex-col gap-[16px]'>
            <label htmlFor="" className='font-medium'>
              Name
            </label>
            <input type="text" placeholder='John Doe' name='name' {...register("name")} required />
          </div>
          <div className='flex flex-col gap-[16px]'>
            <label htmlFor="" className='font-medium'>
              Email Address
            </label>
            <input type="email" placeholder='Youremail@gmail.com' name='email' {...register("email")} required />
          </div>
          <div className='flex flex-col gap-[16px]'>
            <label htmlFor="" className='font-medium'>
              Message
            </label>
            <textarea type="text" placeholder='What is on your mind?' className='h-[100px] resize-none' name='message' {...register("message")} required />
          </div>
          <div className="flex justify-start">
            <button type="submit" className="btn4 px-3 py-2 md:px-4 md:py-2 text-[12px] md:text-[18px] hover:bg-black hover:text-white">SEND</button>
          </div>
        </form>
        <ToastContainer
          position="bottom-left"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
      </div>
      <div className='w-[45%]'>
        <img src={contactImage} alt="" />
        <div className="flex flex-col my-2 gap-[8px] justify-between">
          <h4 className='font-normal'>
            Call Us: <span className="font-medium text-primary">+1667-755-1566</span>
          </h4>
          <h4 className='font-normal'>
            Our Location: <span className="font-medium text-primary">10902 Reisterstown Road. Suite 104. Owings Mills Maryland 21117.</span>
          </h4>
        </div>
        <div className="flex md:mb-[24px] gap-[8px]">
          <h4 className='font-normal'>
            Email Us: <span className="font-medium text-primary">dailypraiseministry@gmail.com</span>
          </h4>
        </div>
      </div>
    </div>
  )
}

export default Contact