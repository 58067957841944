import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { externalLink, youtubePlay } from '../assets'
import { sermons } from '../constants'

const Sermons = () => {
  const navigate = useNavigate()
  const location = useLocation()
  return (
    <div className='bg-dimBlack md:p-[80px] px-4 py-10 text-white'>
      <div className='flex flex-col md:items-center items-start'>
        <h1 className="font-bold md:text-center mb-[24px]">
          OUR PROGRAMS
        </h1>
        <p className="font-medium md:text-center md:w-[60%] w-[80%]">
          Listen to our sermons via our YouTube Channel, if you missed it.. you can catch up here
        </p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 md:gap-[40px] gap-6 md:my-[80px] my-10">
        {
          sermons.map(sermon => (
            <div className="flex items-center p-[24px] justify-between border rounded-[8px]">
              <div className="flex items-center gap-[24px]">
                <img src={youtubePlay} alt="" className='w-10' />
                <div>
                  <p className="font-medium text-[#AAAAAA] md:text-[18px] text-[12px]">
                    {sermon.title}
                  </p>
                  <h2 className="font-bold md:text-[24px] text-[14px]">
                    {sermon.sermonName}
                  </h2>
                </div>
              </div>
              <img src={externalLink} alt="" className='w-6 md:w-10' />
            </div>
          ))
        }
      </div>
      {
        location.pathname === '/' && (<div className="flex justify-center w-full">
          <button className="btn4 px-3 py-2 md:px-4 md:py-2 text-[12px] md:text-[18px] hover:bg-black hover:text-white" onClick={() => navigate('/praises')}>VIEW ALL</button>
        </div>
        )
      }
    </div>
  )
}

export default Sermons